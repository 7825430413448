<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF DISTRICTS</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">
                Date Started
              </th>
              <th class="text-center text-uppercase">
                Registered District
              </th>
              <th class="text-center text-uppercase">
                Category
              </th>
              <th class="text-center text-uppercase">
                Status
              </th>
              <th class="text-center text-uppercase">
                View Officers
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in district_list"
              :key="item.id"
            >
              <td>{{ item.date_established }}</td>
              <td class="text-center">
                {{ item.district_name }}
              </td>
              <td class="text-center">
                <div v-if="item.is_club===1">
                  <v-chip color="warning" dark>CLUB</v-chip>
                </div>
                <div v-else-if="item.is_club===0">
                  <v-chip color="info" dark>DISTRICT</v-chip>
                </div>
              </td>
              <td class="text-center">
                <div v-if="item.is_active===1">
                  <v-chip color="error" dark>DEACTIVATED</v-chip>
                </div>
                <div v-else-if="item.is_active===0">
                  <v-chip color="success" dark>ACTIVE</v-chip>
                </div>
              </td>
              <td class="text-center">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="view_data(item.district_name,item.officers,item.id)"
                >
                  {{icons.mdiEyeCircle}}
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="officers_dialog" persistent max-width="80%">
      <new-officers-in-district
        :name_of_district="name_of_district"
        :district_id="district_id"
        :can_edit="false"
      ></new-officers-in-district>
      <v-btn color="error" @click="officers_dialog = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiEyeCircle} from '@mdi/js'
  import {mapActions} from "vuex";
  import NewOfficersInDistrict from '@/views/admin_interface/NewOfficersInDistrict'

  export default {
    components: {
      NewOfficersInDistrict
    },
    data() {
      return {
        officers_dialog: false,
        district_list: [],

        name_of_district: '',
        district_id: 0,
        key: 0,
        address: '',
        // icons
        icons: {
          mdiEyeCircle,
        },
      }
    },
    mounted() {
      this.list_of_district_only()
        .then(response => {
          this.district_list = response.data
        })
    },
    methods: {
      ...mapActions('district', ['list_of_district_only']),
      view_data(name_of_district, officers, id) {
        this.district_id = id
        this.officers_dialog = true
        this.name_of_district = name_of_district
      },
    }
  }
</script>
